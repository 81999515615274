import React, { useEffect, useRef, useState } from "react";
import Footer from "../universal/footer";
import "./results.css";

const Results = ({ navbarHeight }) => {
    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    useEffect(() => {

        if (section1Ref.current) {
            const section1Height = section1Ref.current.offsetHeight
            setBgHeight(section1Height + navbarHeight);
        }
    }, [navbarHeight]);

    const handleDownload = (pdfPath) => {
        const link = document.createElement("a");
        link.href = pdfPath;
        link.download = pdfPath.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="main-content">
            <img
                src=".././assets/images/backgrounds/bg1.png"
                className="vip-section1-bg"
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className="results-section1" ref={section1Ref}>
                <h1>VIP GROUP RESULTS</h1>
                <div className="results-pdfs">
                    <div className="result">
                        <h2>September</h2>
                        <img src="/.././assets/images/results/result1.png" alt="September Result" />
                        <h3>PNL: <span>+4597.79%</span></h3>
                        <button
                            className="button-dark"
                            onClick={() => handleDownload("/assets/pdf/SEPTEMBER2024.pdf")}
                        >
                            UNLOCK
                        </button>
                    </div>
                    <div className="result">
                        <h2>October</h2>
                        <img src="/.././assets/images/results/result2.png" alt="October Result" />
                        <h3>PNL: <span>+2452.87%</span></h3>
                        <button
                            className="button-dark"
                            onClick={() => handleDownload("/assets/pdf/OCTOBER2024.pdf")}
                        >
                            UNLOCK
                        </button>
                    </div>
                    <div className="result">
                        <h2>November</h2>
                        <img src="/.././assets/images/results/result3.png" alt="November Result" />
                        <h3>PNL: <span>+9952.5%</span></h3>
                        <button
                            className="button-dark"
                            onClick={() => handleDownload("/assets/pdf/NOVEMBER2024.pdf")}
                        >
                            UNLOCK
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Results;
