import React, { useState } from "react";
import './portComp.css';
import BTC from './portflio/BTC.png'
import ETH from './portflio/ETH.png'
import SOL from './portflio/SOL.png'
import XRP from './portflio/XRP.png'
import DOGE from './portflio/DOGE.png'
import QNT from './portflio/QNT.png'
import RIO from './portflio/RIO.png'
import POPCAT from './portflio/POPCAT.png'

const PortfolioComp = () => {
    const [password, setPassword] = useState("");
    const [verification, setVerification] = useState(false);
    const [message, setMessage] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {    
            // Make the fetch request
            const response = await fetch("https://cryptoxnft.org/password/verify-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // Match curl's Content-Type
                },
                body: JSON.stringify({ password: password }), // Send the password as JSON
            });
    
            // Check if the response status is OK (status code 200)
            if (!response.ok) {
                console.error(`Error: API returned status ${response.status} - ${response.statusText}`);
                setMessage(`Error: ${response.status} - ${response.statusText}`);
                return;
            }
    
            // Parse the response JSON
            const data = await response.json();
    
            // Handle the response
            if (data.match) {
                setVerification(true);
                setMessage(data.message || "Password is correct.");
            } else {
                setVerification(false);
                setMessage(data.message || "Password is incorrect.");
            }
        } catch (error) {
            // Catch any fetch-related errors
            console.error("Error during password verification fetch:", error);
            setMessage("An error occurred while verifying the password.");
        }
    };
    
    // Coin data
    const coins = [
        { name: "BTC", logo: BTC, entryPriceRange: "$68K - $52K", sellPriceRange: "$73K - $100K", multiplier: "4X" },
        { name: "ETH", logo: ETH, entryPriceRange: "$2.45K - $2.25K", sellPriceRange: "$2.6K - $3.6K", multiplier: "3X" },
        { name: "SOL", logo: SOL, entryPriceRange: "$160 - $140", sellPriceRange: "$180 - $300", multiplier: "5X" },
        { name: "XRP", logo: XRP, entryPriceRange: "$0.5 - $0.38", sellPriceRange: "$0.6 - $1.35", multiplier: "4X" },
        { name: "DOGE", logo: DOGE, entryPriceRange: "$0.148", sellPriceRange: "$0.165 - $0.3", multiplier: "3X" },
        { name: "QNT", logo: QNT, entryPriceRange: "$55", sellPriceRange: "$150", multiplier: "3X" },
        { name: "POPCAT", logo: POPCAT, entryPriceRange: "$1.33", sellPriceRange: "$2.5", multiplier: "5X" },
        { name: "RIO", logo: RIO, entryPriceRange: "$0.66 - $0.5", sellPriceRange: "$1.05 - $3.3", multiplier: "4X" }
    ];

    return (
        <div className="port-outter-container">
            {!verification && (
                <div className="port-form-wrapper">
                    <form onSubmit={handleSubmit} className="port-form">
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <button type="submit" className='button-dark-port'>SUBMIT</button>
                    </form>
                    <p style={{color: '#fff'}}>{message}</p>
                </div>
            )}
            <div className={`port-content ${verification ? "" : "blurred"}`}>
                <table className="coin-table">
                    <thead>
                        <tr>
                            <th>COIN</th>
                            <th>ENTRY</th>
                            <th>TARGET</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coins.map((coin, index) => (
                            <tr key={index}>
                                <td className="coin-cell">
                                    <img src={coin.logo} alt={`${coin.name} logo`} className="coin-logo" />
                                    <span>{coin.name}</span>
                                </td>
                                <td>{coin.entryPriceRange}</td>
                                <td>{coin.sellPriceRange}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PortfolioComp;
