import React, {useState, useRef, useEffect} from "react"
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import './copyTrading.css';

const CopyBlofin = ({navbarHeight}) => {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const button1Ref = useRef(null);
    const [button2Width, setButton2Width] = useState(0);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100);

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight)
        if (button1Ref.current) {
            const button1Width = button1Ref.current.offsetWidth
            setButton2Width(button1Width)
        }
    })

    return(
        <div className="main-content">

            <div className="copy-section1" ref={section1Ref}>
                <img src='../.././assets/images/backgrounds/bg1.png'
                    className='copy-section1-bg'
                    style={{ height: `${bgHeight}px` }}
                    alt="Background"
                />
                <div className="copy-section1-header">
                    <h1><span>$1,000</span> TO <span>$100,000</span> 2024 CHALLENGE</h1>
                    <h2>COPY CRYPTO WHALES WITH OUR FREE COPY TRADING PROGRAM!</h2>
                </div>
                <div className="copy-section1-buttons">
                    <a href="https://blofin.com/invite/CRYPTOXNFT" target="_blank"><button className="button-light" style={{width:`${button2Width}px`}}>SIGN UP</button></a>
                    <button onClick={() => scrollToSection(section3Ref)} className="button-light" ref={button1Ref}>RESULTS</button>
                </div>
                <div className="copy-section1-horizontal-line"></div>
                <div className="copy-section1-images-main">
                    <div className="copy-section1-images-left">
                        <div className="copy-section1-images-wrapper">
                            <h2>7D PnL: $17,810.98</h2>
                            <img src="../.././assets/images/services/copy/blofin/data.png" alt="7 day pnl"/>
                        </div>
                    </div>
                    <div className="copy-section1-images-right">
                        <div className="copy-section1-images-wrapper">
                            <h2>ROI: 1956.57%</h2>
                            <img src="../.././assets/images/services/copy/blofin/roi.png" alt="roi"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copy-section2" ref={section2Ref}>
                <h1>JOIN US TODAY</h1>
                <h2>- LIMITED PLACES -</h2>
                <div className="copy-section2-steps">
                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 1</h2>
                        <h3>Create a Blofin Account</h3>
                        <p>Only “cryptoxnft” promo code counts!</p>
                        <a href="https://blofin.com/invite/CRYPTOXNFT" target="_blank"><button>CREATE ACCOUNT</button></a>
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 2</h2>
                        <h3>Download Blofin App</h3>
                        <div className="copy-step-images">
                            <a href="https://apps.apple.com/us/app/blofin/id1616804346" target="_blank"><img src="../.././assets/images/services/copy/appstore.png" alt="Download App 1"/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.blofin.android" target="_blank"><img src="../.././assets/images/services/copy/googleplay.png" alt="Download App 2"/></a>
                        </div>      
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 3</h2>
                        <h3>Deposit minimum 500$</h3>
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 4</h2>
                        <h3>Copy our trades on BLOFIN</h3>
                        <a href="https://blofin.com/copy-trade" target="_blank"><button>COPY TRADES</button></a>
                    </div>
                </div>
                <h1>YOU MUST USE PROMO CODE: <span>CRYPTOXNFT</span></h1>
                <h2>Join our FREE TELEGRAM GROUP for daily insights!</h2>
                <a href="https://t.me/cryptoxnfts" target="_blank"><button>TELEGRAM</button></a>
            </div>

            <div className="copy-section3" ref={section3Ref}>
                <h1>RESULTS</h1>
                <div className="copy-section3-row">
                    <div className="copy-section3-left">
                        <div className="copy-section3-left-content">
                            <h2>7 DAY PNL: +17,810.98$</h2>
                            <img src="../.././assets/images/services/copy/blofin/data.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-right">
                        <div className="copy-section3-right-content">
                            <h2>ROI: +1956.57%</h2>
                            <img src="../.././assets/images/services/copy/blofin/data2.png" alt="image"/>
                        </div>
                    </div>
                </div>

                <div className="copy-section3-row">
                    <div className="copy-section3-left">
                        <div className="copy-section3-left-content">
                            <h2>PnL</h2>
                            <img src="../.././assets/images/services/copy/blofin/profit.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-right">
                        <div className="copy-section3-right-content">
                            <h2>ROI Graph</h2>
                            <img src="../.././assets/images/services/copy/blofin/roi.png" alt="image"/>
                        </div>
                    </div>
                </div>

                <button onClick={() => scrollToSection(section2Ref)}>SIGN UP</button>

                <div className="copy-section3-pnl-main">
                    <div className="copy-section3-pnl-row">
                        <div className="copy-section3-pnl-row-images">
                            <img src="../.././assets/images/services/copy/blofin/pnl1.png" alt="image"/>
                            <img src="../.././assets/images/services/copy/blofin/pnl2.png" alt="image"/>
                            <img src="../.././assets/images/services/copy/blofin/pnl3.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-pnl-row">
                        <div className="copy-section3-pnl-row-images">
                            <img src="../.././assets/images/services/copy/blofin/pnl4.png" alt="image"/>
                            <img src="../.././assets/images/services/copy/blofin/pnl5.png" alt="image"/>
                            <img src="../.././assets/images/services/copy/blofin/pnl6.png" alt="image"/>
                        </div>
                    </div>
                        
                    <button onClick={() => scrollToSection(section2Ref)}>SIGN UP</button>
                </div>
            </div>

        </div>
    )
}

export default CopyBlofin