import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './card.css';
import { FiClock } from 'react-icons/fi';
import { AiOutlineAim } from 'react-icons/ai';
import { MdTrendingUp } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { HiOutlineDocumentText } from 'react-icons/hi';

const VipCard = () => {
    const [iconSize, setIconSize] = useState(22);
    const buttonsDivRef = useRef(null);
    const [combinedWidth, setCombinedWidth] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const isDesktop = window.innerWidth >= 769;

            if (isDesktop && buttonsDivRef.current) {
                const buttonsDivWidth = buttonsDivRef.current.offsetWidth;
                setCombinedWidth(buttonsDivWidth);
            } else {
                setCombinedWidth('auto');
            }

            setIconSize(isDesktop ? 22 : 12);
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className='vipcard-main'>
            <div className='vipcard-container'>
                <h1>VIP MEMBERSHIP</h1>

                <div
                    className='vipcard-text'
                    style={{ width: combinedWidth === 'auto' ? 'auto' : `${combinedWidth}px` }}
                >
                    <div className='paragraph one'>
                        <div className='vipcard-icon'>
                            <FiClock size={iconSize} />
                        </div>
                        <p>Perfect Updates 24/7</p>
                    </div>
                    <div className='paragraph two'>
                        <div className="vipcard-icon">
                            <AiOutlineAim size={iconSize} />
                        </div>
                        <p>Exact Entries and Targets</p>
                    </div>
                    <div className='paragraph three'>
                        <div className="vipcard-icon">
                            <MdTrendingUp size={iconSize} />
                        </div>
                        <p>Spot & Futures Signals</p>
                    </div>
                    <div className='paragraph four'>
                        <div className="vipcard-icon">
                            <FiUsers size={iconSize} />
                        </div>
                        <p>Free Copy Trading</p>
                    </div>
                    <div className='paragraph five'>
                        <div className="vipcard-icon">
                            <HiOutlineDocumentText size={iconSize} />
                        </div>
                        <p>E-Book Access</p>
                    </div>
                </div>

                <div className='vipcard-buttons' ref={buttonsDivRef}>
                    <Link to="/results">
                        <button className='button-vip'>RESULTS</button>
                    </Link>
                    <div className="vipcard-button">
                        <Link to="/services/vip/payment">
                            <button className="button-vip">JOIN NOW</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VipCard;
