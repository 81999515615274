import React, { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Footer from '../universal/footer';
import './contact.css'

function Contact({navbarHeight}) {

 const footerRef = useRef(null);
 const headerRef = useRef(null);
 const pageHeightRef = useRef(null);
 const windowHeight = window.innerHeight;
 const [bgHeight, setBgHeight] = useState(0);
 const [mainHeight, setMainHeight] = useState(0);
 const [paddingTopForm, setPaddingTopForm] = useState(0)
 const [widthForm, setWidthForm] = useState(0)

 useEffect(() => {
  if (footerRef.current && pageHeightRef) {
    const footerHeight = footerRef.current.offsetHeight;
    const pageHeight = pageHeightRef.current.offsetHeight;
    setBgHeight(pageHeight + navbarHeight)
    setMainHeight(windowHeight - navbarHeight - footerHeight)
  }

 }, [navbarHeight]);

 useEffect(() => {
  if (headerRef.current) {
    const headerHeight = headerRef.current.offsetHeight;
    const headerWidth = headerRef.current.offsetWidth;
    setPaddingTopForm((headerHeight / 2) + 56);
    setWidthForm(headerWidth);
  }
 },[])

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_1jf86wp', 'template_oah78kp', formData, 'yqtposH6pXYkAxJ0x')
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Reset form fields
      }, (error) => {
        console.error('Error sending message:', error.text);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className='main-content'>
      <img src='.././assets/images/backgrounds/bg1.png'
        className='vip-section1-bg'
        style={{ minHeight: `${bgHeight}px` }}
        alt="Background"
      />

      <div className='contact-main' ref={pageHeightRef} style={{minHeight:`${mainHeight}px`}}>
        <div className='contact-main-wrapper'>
          <div className='form-header' ref={headerRef}>
            <h1>SEND US A MESSAGE</h1>
          </div>
          <div className='contact-form' style={{paddingTop: `${paddingTopForm}px`, width: `${widthForm}px`}}>
            <form onSubmit={handleSubmit}>
              <div className='contact-field top'>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='contact-field'>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='contact-field'>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div> 
      <div className='footer'ref={footerRef}><Footer /></div>
    </div>
  );
}

export default Contact;
