import React, {useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../../universal/footer";
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import './blofin.css'

const XrpCryptos = ({navbarHeight}) => {

    const name = "Xrp_Cryptos"

    const section1Ref = useRef(null);
    const button1ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);

    const [link, setLink] = useState("");
    const [promoCode, setPromoCode] = useState("");

    const [bgHeight, setBgHeight] = useState(0);
    const [button2Width, setButton2Width] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);

        if (button1ref.current) {
            const button1Width = button1ref.current.offsetWidth;
            setButton2Width(button1Width);
        }
    });

    useEffect(() => {
        const randomNumber = Math.random();
        const decider = randomNumber.toFixed(2);

        if (decider > 1) {
            setLink("https://partner.blofin.com/d/CRYPTOXNFT");
            setPromoCode("cryptoxnft");
        }
        else{
            setLink("https://partner.blofin.com/d/cryptobtc")
            setPromoCode("cryptobtc")
        }
    },[]);

    const scrollToSection = (sectionRef) => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return(
        <div className="main-content">
            <img
            className='section1-bg' 
            src='.././assets/images/backgrounds/bg1.png'
            style={{ height: `${bgHeight}px`}}
            alt="Background"
            />

            <div className="blofin-aff-section1" ref={section1Ref}>
                <div className="blofin-aff-section1-header">
                    <div className="aff-images">
                        <img className="aff-image" alt="partner logo" src=".././assets/images/affiliate/logos/xrp_cryptos.png" />
                        <div className="vr-line" style={{display:'none'}}></div>
                        <img alt="cryptoxnft logo" src=".././assets/images/logo.jpg" style={{display:'none'}}/>
                    </div>
                    <div className="aff-names">
                        <h1>JOIN <span>{name}</span> ARMY 📈💵</h1>
                    </div> 
                    <div className="blofin-aff-section1-header-text">
                        <h1><span >$1,000</span> TO <span>$100,000</span> 2024 CHALLENGE</h1>
                        <h2>With promo code <span id="promoCodeName">{promoCode}</span> get free <span>$100</span> & <span>$5,000</span> bonus</h2>
                    </div>     
                    <div className="blofin-aff-section1-buttons">
                        <button onClick={() => scrollToSection(section2Ref)} className="blofin-button-dark" style={{width:`${button2Width}px`}}>SIGN UP</button>
                        <button onClick={() => scrollToSection(section3Ref)} className="blofin-button-dark" ref={button1ref}>RESULTS</button>
                    </div>                                                                          
                </div>
                <div className="blofin-aff-section1-hrline"></div>
                <div className="blofin-copy-section1-images-main">


                    <div className="blofin-copy-section1-images-right">
                        <div className="blofin-copy-section1-images-wrapper">
                            <h2 style={{color:'#fff'}}>7D WIN RATE: 100%</h2>
                            <img src="../.././assets/images/affiliate/blofin/data.png" alt="current balance"/>
                        </div>        
                    </div>
                </div>                
            </div>

            <div className="blofin-aff-section2" ref={section2Ref}>
                <h1>JOIN US TODAY</h1>
                <h2>- LIMITED PLACES -</h2>
                <div className="blofin-aff-section2-steps">

                    <div className="blofin-aff-step">
                        <h2 className="blofin-aff-steps-header">STEP 1</h2>
                        <h3>Create a <span>BLOFIN</span> Account</h3>
                        <p>Only {name} promo code counts!</p>
                        {/* Use the random link here */}
                        <a href={link} target="_blank"><button className="blofin-button-dark-steps">CREATE ACCOUNT</button></a>
                    </div>

                    <div className="blofin-aff-step">
                        <h2 className="blofin-aff-steps-header">STEP 2</h2>
                        <h3>Download <span>BLOFIN</span> App</h3>
                        <div className="blofin-aff-step-images">
                            <a href="https://apps.apple.com/us/app/blofin/id1616804346" target="_blank"><img src=".././assets/images/services/copy/appstore.png" alt="Download App 1"/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.blofin.android&hl=en" target="_blank"><img src=".././assets/images/services/copy/googleplay.png" alt="Download App 2"/></a>
                        </div>      
                    </div>   

                    <div className="blofin-aff-step">
                        <h2 className="blofin-aff-steps-header">STEP 3</h2>
                        <h3>Deposit minimum 100$</h3>
                    </div>  


                    <div className="blofin-aff-step">
                        <h2 className="blofin-aff-steps-header">STEP 4</h2>
                        <h3>Copy our trades on <span>BLOFIN</span></h3>
                        <Link to={'/blofin-guide'}><button className="blofin-button-dark-steps">COPY TRADES</button></Link>
                    </div>
                </div>
                <h1 className="blofin-aff-section2-footer">GET A FREE VIP TRIAL AFTER DEPOSITING</h1>
                <h2 className="blofin-aff-section2-footer">REDEEM YOUR TRIAL USING THE BUTTON BELOW</h2>
                <a href="https://t.me/cryptoxnftsignals" target="_blank"><button className="blofin-button-dark-steps">REDEEM</button></a>
            </div>

            <div className="blofin-aff-section3" ref={section3Ref}>
            <h1>RESULTS</h1>
                <div className="blofin-aff-section3-row">
                    <div className="blofin-aff-section3-left">
                        {/*<div className="blofin-aff-section3-left-content">
                            <h2>90D DAY PNL</h2>
                            <img src="../.././assets/images/affiliate/blofin/profit.png" alt="90d pnl"/>
                        </div>*/}
                    </div>
                    <div className="blofin-aff-section3-right">
                        <div className="blofin-aff-section3-right-content">
                            <h2>7D WIN RATE: 100%</h2>
                            <img src="../.././assets/images/affiliate/blofin/data.png" alt="image"/>
                        </div>
                    </div>
                </div> 

                <button className="blofin-button-dark" onClick={() => scrollToSection(section2Ref)}>SIGN UP</button>               

                <div className="blofin-aff-section3-pnl-main">
                    <div className="blofin-aff-section3-pnl-row">
                        <div className="blofin-aff-section3-pnl-row-images">
                            <img src="../.././assets/images/affiliate/blofin/pnl1.png" alt="image"/>
                            <img src="../.././assets/images/affiliate/blofin/pnl2.png" alt="image"/>
                            <img src="../.././assets/images/affiliate/blofin/pnl3.png" alt="image"/>
                        </div>
                    </div>
                    <div className="blofin-aff-section3-pnl-row">
                        <div className="blofin-aff-section3-pnl-row-images">
                            <img src="../.././assets/images/affiliate/blofin/pnl4.png" alt="image"/>
                            <img src="../.././assets/images/affiliate/blofin/pnl5.png" alt="image"/>
                            <img src="../.././assets/images/affiliate/blofin/pnl6.png" alt="image"/>
                        </div>
                    </div>
                
                    <button onClick={() => scrollToSection(section2Ref)} className="blofin-button-dark">SIGN UP</button>
                </div>

            </div>
            <div className="footer"><Footer/></div>
        </div>
    )
}

export default XrpCryptos;